// TODO make the cookie methods into composables and look for alternatives for localstorage (indexeddb or else)
import { useRemoveCookie } from '~/composables/useRemoveCookie'

/** @deprecated use composables/useCleanCache.ts */
export function cleanInfSignup() {
  if (import.meta.client) {
    window.localStorage.removeItem('authCreateUserBackup')
    window.localStorage.removeItem('createBaseInfluencerBackup')
    window.localStorage.removeItem('patchLinkInfluencerBackup')
    window.localStorage.removeItem('patchSubsAndGenreBackup')
  }
}

/** @deprecated use composables/useCleanCache.ts */
export function cleanBandSignup() {
  if (import.meta.client) window.localStorage.removeItem('flowSignupBackUp')
}

/** @deprecated use composables/useCleanCache.ts */
export function cleanCacheLang() {
  // TODO: check this cookie
  useRemoveCookie('switchLang')
  // this.$cookies.remove('switchLang')
}

/** @deprecated use composables/useCleanCache.ts */
export function cleanPaypalAuthBackup() {
  // TODO: check this cookie
  useRemoveCookie('paypal_pending_payment')
  // this.$cookies.remove('paypal_pending_payment')
}

/** @deprecated use composables/useCleanCache.ts */
export function cleanDraftProgress(draft_id = 0) {
  if (import.meta.client) {
    ;[
      'past_sendtrack_step',
      'past_selection_step',
      'past_message_step',
      'past_recap_step',
    ].forEach((key) => {
      // TODO: check this cookie
      useRemoveCookie(`${key}_${draft_id}`)
      // this.$cookies.remove(`${key}_${draft_id}`)
    })
  }
}

/** @deprecated use composables/useCleanCache.ts */
export function cleanCacheAll() {
  cleanBandSignup()
  cleanInfSignup()
  cleanCacheLang()
  cleanPaypalAuthBackup()
}
